import { AppBar, Box, Divider, IconButton, Link, List, ListItemButton, ListItemIcon, ListItemText, Slide, Toolbar, useScrollTrigger } from '@mui/material'
import * as Icons from '@mui/icons-material'
import ThemeToggler from 'components/navigation/theme-toggler'
import UserAvatar from 'components/navigation/user-avatar'
import { NavConfigs, NavConfig, NavType, externalLinkProps } from 'utils/config'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { MuiNavLink } from './mui-nav-link'
import { ResponsiveDrawer } from './drawer'
import { SmallDivider } from 'components/divider'

const DrawerToggler = ({ handleOpen }: { handleOpen: () => void }): React.ReactElement => {
  return (
    <IconButton
      edge='end'
      color='inherit'
      aria-label='open nav drawer'
      onClick={handleOpen}
      size='large'
    >
      <Icons.Menu />
    </IconButton>
  )
}

const getNavLinkItem = (item: NavConfig): React.ReactElement => {
  const properties = {
    component: MuiNavLink,
    to: item.path,
    end: item.isExact
  }
  return (
    <ListItemButton
      key={item.title}
      {...properties}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.title} primaryTypographyProps={{ noWrap: true }} />
    </ListItemButton>
  )
}

const getExternalLinkItem = (item: NavConfig): React.ReactElement => {
  return (
    <ListItemButton
      key={item.title}
      component='a'
      href={item.path}
      {...externalLinkProps}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.title} primaryTypographyProps={{ noWrap: true }} />
    </ListItemButton>
  )
}

const getDividerItem = (item: NavConfig): React.ReactElement => {
  return (
    <SmallDivider key={item.title}>{item.title}</SmallDivider>
  )
}

const getNavListItem = (item: NavConfig): React.ReactElement => {
  switch (item.type) {
    case NavType.LINK:
      return getNavLinkItem(item)
    case NavType.EXT_LINK:
      return getExternalLinkItem(item)
    case NavType.DIVIDER:
      return getDividerItem(item)
    default:
      return <></>
  }
}

const Navigation = (): React.ReactElement => {
  const [showDrawer, setDrawer] = useState<boolean>(false)

  const drawerItems = (
    <>
      <Toolbar
        sx={{ background: (theme) => theme.palette.mode === 'light' ? 'primary.main' : 'background.paper' }}
      >
        <Box flexGrow={1} />
        <UserAvatar />
      </Toolbar>
      <Divider />
      <List>
        {NavConfigs.map((item) => getNavListItem(item))}
        <SmallDivider key='Config stuff'>Config stuff</SmallDivider>
        <ThemeToggler />
      </List>
    </>
  )

  return (
    <>
      <Slide appear={false} direction='down' in={!useScrollTrigger({ target: window })}>
        <AppBar
          sx={{ background: (theme) => theme.palette.mode === 'light' ? 'primary.main' : 'background.paper' }}
          position='sticky'
        >
          <Toolbar>
            <Link
              variant='h6'
              underline='none'
              color='inherit'
              component={NavLink} to='/'
            >
              Arthur
            </Link>
            <Box flexGrow={1} />
            <DrawerToggler handleOpen={() => setDrawer(true)} />
          </Toolbar>
        </AppBar>
      </Slide>
      <ResponsiveDrawer show={showDrawer} handleHide={() => setDrawer(false)}>
        {drawerItems}
      </ResponsiveDrawer>
    </>
  )
}

export default Navigation
