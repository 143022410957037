import { Drawer } from '@mui/material'
import React from 'react'

interface ResponsiveDrawerProps {
  show: boolean
  handleHide: () => void
  children: React.ReactNode
}

export const ResponsiveDrawer = ({ show, handleHide, children }: ResponsiveDrawerProps): React.ReactElement => {
  return (
    <Drawer
      variant='temporary'
      anchor='right' // anchor={theme.direction === 'rtl' ? 'right' : 'left'}
      open={show}
      onClose={handleHide}
      ModalProps={{ keepMounted: true }} // Better open performance on mobile.
      onClick={handleHide}
      onKeyDown={handleHide}
    >
      {children}
    </Drawer>
  )
}
