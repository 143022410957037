import { SerializedError } from '@reduxjs/toolkit'

export enum ApiStatus {
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED'
}

export interface ApiState {
  status: ApiStatus
  error?: SerializedError
}
