import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getKing, getKingUser, setKing } from 'api/arthurkamwt'

import { ApiState, ApiStatus } from 'reducers/common'

// TODO: refactor me
export interface KingState {
  currentKing?: {
    id: string
    name: string
  }
  throttle?: number

  getKing: ApiState
  setKing: ApiState
  getKingUser: ApiState
}

const initialState: KingState = {
  getKing: { status: ApiStatus.FULFILLED },
  setKing: { status: ApiStatus.FULFILLED },
  getKingUser: { status: ApiStatus.FULFILLED }
}

export const callGetKing = createAsyncThunk(
  'king/get',
  async () => {
    return await getKing()
  }
)

export const callGetKingUser = createAsyncThunk(
  'king/user/get',
  async () => {
    return await getKingUser()
  }
)

export const callSetKing = createAsyncThunk(
  'king/set',
  async () => {
    return await setKing()
  }
)

const kingSlice = createSlice({
  name: 'king',
  initialState: initialState,
  reducers: {
  },
  extraReducers: builder => {
    // callGetKing
    builder.addCase(callGetKing.pending, (state) => {
      state.getKing = {
        status: ApiStatus.PENDING,
        error: undefined
      }
    })
    builder.addCase(callGetKing.fulfilled, (state, action) => {
      state.getKing = {
        status: ApiStatus.FULFILLED,
        error: undefined
      }
      state.currentKing = { ...action.payload }
    })
    builder.addCase(callGetKing.rejected, (state, action) => {
      state.getKing = {
        status: ApiStatus.REJECTED,
        error: action.error
      }
    })

    // callSetKing
    builder.addCase(callSetKing.pending, (state) => {
      state.setKing = {
        status: ApiStatus.PENDING,
        error: undefined
      }
    })
    builder.addCase(callSetKing.fulfilled, (state, action) => {
      state.setKing = {
        status: ApiStatus.FULFILLED,
        error: undefined
      }
      state.currentKing = action.payload
      state.throttle = action.payload.timestamp
    })
    builder.addCase(callSetKing.rejected, (state, action) => {
      state.setKing = {
        status: ApiStatus.REJECTED,
        error: action.error
      }
    })

    // callGetKingUser
    builder.addCase(callGetKingUser.pending, (state) => {
      state.getKingUser = {
        status: ApiStatus.PENDING,
        error: undefined
      }
    })
    builder.addCase(callGetKingUser.fulfilled, (state, action) => {
      state.getKingUser = {
        status: ApiStatus.FULFILLED,
        error: undefined
      }
      state.throttle = action.payload.timestamp
    })
    builder.addCase(callGetKingUser.rejected, (state, action) => {
      state.getKingUser = {
        status: ApiStatus.REJECTED,
        error: action.error
      }
    })
  }
})

export default kingSlice.reducer
