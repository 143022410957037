import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

export const MuiNavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>((navLinkProps, ref) => {
  const { className: previousClasses, ...rest } = navLinkProps
  const elementClasses = previousClasses?.toString() ?? ''
  return (
    <NavLink
      {...rest}
      ref={ref}
      className={({ isActive }) => (isActive ? elementClasses + ' Mui-selected' : elementClasses)}
    />
  )
})
MuiNavLink.displayName = 'MuiNavLink'
