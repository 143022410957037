/* eslint-disable */
import { AppDispatch } from 'reducers'
import { callGetSession, callPostSession } from 'reducers/session'
import { ScriptStatus, setGapiStatus } from 'reducers/misc'

const clientId = '443447561556-6o5j5timrmbkpu35ki1ctkma3qn2b583.apps.googleusercontent.com'
const scriptId = 'google-sign-in-script'

export const initGoogleSignIn = (dispatch: AppDispatch): void => {
  const script = document.createElement('script')
  script.onload = () => {
    window.google!.accounts.id.initialize({
      client_id: clientId,
      callback: (res) => {
        if (res.credential) dispatch(callPostSession(res.credential))
      },
      auto_select: true
    })

    dispatch(setGapiStatus(ScriptStatus.LOADED))
    dispatch(callGetSession())

    // todo: figure out when to prompt
    // window.google!.accounts.id.prompt()
  }
  script.id = scriptId
  script.async = true
  script.src = 'https://accounts.google.com/gsi/client'
  document.head.appendChild(script)
}

export const renderButton = (isLight: boolean): void => {
  if (!window.google) return

  const button = document.getElementById('google-sign-in')
  if (!button) return

  window.google.accounts.id.renderButton(
    button,
    {
      type: 'standard',
      theme: isLight ? 'outline' : 'filled_black'
    },
  )
}
