import { createSlice } from '@reduxjs/toolkit'

export interface SettingsState {
  themeType: 'light' | 'dark'
}

export interface SetThemeTypeAction {
  payload: 'light' | 'dark'
}

const currentHour = new Date().getHours()
const initialState: SettingsState = {
  themeType: (currentHour >= 6 && currentHour < 18 ? 'light' : 'dark')
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setThemeType: (_state: SettingsState, action: SetThemeTypeAction): SettingsState => {
      return {
        ..._state,
        themeType: action.payload
      }
    }
  }
})

export default settingsSlice.reducer
export const { setThemeType } = settingsSlice.actions
