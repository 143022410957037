import * as Icons from '@mui/icons-material'
import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'

export const externalLinkProps = {
  target: '_blank',
  rel: 'noopener'
}

export interface RouteConfig {
  path?: string
  index?: boolean
  element?: React.ReactElement
  children?: RouteConfig[]
}

const Bio = lazy(async () => await import('pages/bio'))
const Bionicle = lazy(async () => await import('pages/bionicle'))
const BirthdayWho = lazy(async () => await import('pages/birthday/who'))
const BirthdaySong = lazy(async () => await import('pages/birthday/song'))
const King = lazy(async () => await import('pages/king'))
const Rain = lazy(async () => await import('pages/rain'))
const Rgb = lazy(async () => await import('pages/rgb'))
const Test = lazy(async () => await import('pages/test'))
const Wgl = lazy(async () => await import('pages/wgl'))
const Yule = lazy(async () => await import('pages/yule'))

export const RouteConfigs: RouteConfig[] = [
  {
    path: '/',
    element: (<Bio />)
  },
  {
    path: 'bionicle',
    element: (<Bionicle />)
  },
  {
    path: 'birthday',
    children: [
      {
        index: true,
        element: (<BirthdayWho />)
      },
      {
        path: ':name',
        element: (<BirthdaySong />)
      }
    ]
  },
  {
    path: 'king',
    element: (<King />)
  },
  {
    path: 'rain',
    element: (<Rain />)
  },
  {
    path: 'rgb',
    element: (<Rgb />)
  },
  {
    path: 'wgl',
    element: (<Wgl />)
  },
  {
    path: 'yule',
    element: (<Yule />)
  },
  {
    path: 'test',
    element: (<Test />)
  },
  {
    path: '*',
    element: (<Navigate replace to='/' />)
  }
]

export enum NavType {
  LINK,
  EXT_LINK,
  DIVIDER,
}

export interface NavConfig {
  type: NavType
  title: string
  path?: string
  icon?: React.ReactElement
  isExact?: boolean
}

export const NavConfigs: NavConfig[] = [
  {
    type: NavType.LINK,
    title: 'Home',
    path: '/',
    isExact: true,
    icon: <Icons.Home />
  },
  {
    type: NavType.EXT_LINK,
    title: 'Blog',
    path: 'https://arthurkamwt.wordpress.com/',
    icon: <Icons.Assignment />
  },
  {
    type: NavType.DIVIDER,
    title: 'Fun stuff'
  },
  {
    type: NavType.LINK,
    title: 'Bionicle',
    path: 'bionicle',
    icon: <Icons.Gamepad />
  },
  {
    type: NavType.LINK,
    title: 'Birthday',
    path: 'birthday',
    icon: <Icons.Cake />
  },
  {
    type: NavType.LINK,
    title: 'King of the Hill',
    path: 'king',
    icon: <Icons.FilterHdr />
  },
  {
    type: NavType.LINK,
    title: 'RGB',
    path: 'rgb',
    icon: <Icons.Colorize />
  },
  {
    type: NavType.DIVIDER,
    title: 'Looky stuff'
  },
  {
    type: NavType.LINK,
    title: 'Rain',
    path: 'rain',
    icon: <Icons.Cloud />
  },
  {
    type: NavType.LINK,
    title: 'Yule',
    path: 'yule',
    icon: <Icons.Fireplace />
  },
  {
    type: NavType.LINK,
    title: 'WGL',
    path: 'wgl',
    icon: <Icons.Brush />
  }
]
