import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getKing, test } from 'api/arthurkamwt'

import { ApiState, ApiStatus } from 'reducers/common'

export interface TestState extends ApiState { }

const initialState: TestState = {
  status: ApiStatus.FULFILLED
}

export const callTestApi = createAsyncThunk(
  'testapi/call',
  async () => {
    await test()
    return await getKing()
  }
)

const testSlice = createSlice({
  name: 'test',
  initialState: initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(callTestApi.pending, (state, action) => {
      state.status = ApiStatus.PENDING
      console.log(action)
    })
    builder.addCase(callTestApi.fulfilled, (state, action) => {
      state.status = ApiStatus.FULFILLED
      console.log(action)
    })
    builder.addCase(callTestApi.rejected, (state, action) => {
      state.status = ApiStatus.REJECTED
      console.log(action)
    })
  }
})

export default testSlice.reducer
