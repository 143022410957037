import { AnyAction, combineReducers, configureStore, ThunkDispatch } from '@reduxjs/toolkit'
import kingReducer from 'reducers/king'
import sessionReducer from 'reducers/session'
import settingsReducer from 'reducers/settings'
import userReducer from 'reducers/misc'
import testReducer from 'reducers/test'
import logger from 'redux-logger'
import thunk from 'redux-thunk'

const rootReducer = combineReducers({
  sessionState: sessionReducer,
  settingsState: settingsReducer,
  userState: userReducer,
  kingState: kingReducer,
  testState: testReducer
})

const middleware = process.env.NODE_ENV === 'production'
  ? [
      thunk
    ]
  : [
      thunk,
      logger
    ]

const store = configureStore({
  reducer: rootReducer,
  middleware: middleware
})

export default store
export type AppState = ReturnType<typeof rootReducer>
export type AppDispatch = ThunkDispatch<AppState, void, AnyAction>
