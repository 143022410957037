import { createSlice } from '@reduxjs/toolkit'

export enum ScriptStatus {
  INIT,
  LOADED
}

export interface MiscState {
  gapiStatus: ScriptStatus
}

const initialState: MiscState = {
  gapiStatus: ScriptStatus.INIT
}

const miscSlice = createSlice({
  name: 'misc',
  initialState,
  reducers: {
    setGapiStatus: (state, action) => {
      return {
        ...state,
        gapiStatus: action.payload
      }
    }
  }
})

export default miscSlice.reducer
export const { setGapiStatus } = miscSlice.actions
