import { Box, CssBaseline, ThemeProvider, StyledEngineProvider, Typography, LinearProgress } from '@mui/material'
import Navigation from 'components/navigation'
import { RouteConfigs } from 'utils/config'
import React, { Suspense, useEffect } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import store, { AppState } from 'reducers'
import { SettingsState } from 'reducers/settings'
import { initGoogleSignIn } from 'utils/gapi'
import { getMuiTheme } from 'utils/theme'

// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

const Main = (): React.ReactElement => (
  <Box component='main' flexGrow={1}>
    <Suspense fallback={<LinearProgress />}>
      {useRoutes(RouteConfigs)}
    </Suspense>
  </Box>
)

const Footer = (): React.ReactElement => (
  <Box component='footer' width='100%' p={2} style={{ justifySelf: 'flex-end' }}>
    <Typography
      variant='caption'
      color='textSecondary'
      display='block'
      align='center'
    >
      &copy; Arthur Kam
    </Typography>
  </Box>
)

const RoutedApp = (): React.ReactElement => (
  <Box sx={{ minHeight: '100vh' }} display='flex' flexDirection='column'>
    <BrowserRouter>
      <Navigation />
      <Main />
    </BrowserRouter>
    <Footer />
  </Box>
)

const ThemedApp = (): React.ReactElement => {
  const dispatch = useDispatch()
  const settings = useSelector<AppState, SettingsState>(state => state.settingsState)

  useEffect(() => {
    initGoogleSignIn(dispatch)
  }, [])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getMuiTheme(settings.themeType)}>
        <CssBaseline />
        <RoutedApp />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

const App = (): React.ReactElement => (
  <Provider store={store}>
    <ThemedApp />
  </Provider>
)

export default App
