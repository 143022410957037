import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { deleteSession, getSession, postSession } from 'api/arthurkamwt'

import { ApiState, ApiStatus } from 'reducers/common'

export interface User {
  id: string
  name: string
  picture: string
}

export interface Session {
  user?: User
  // and others
}

export interface SessionState {
  session: Session
  getSession: ApiState
  postSession: ApiState
  deleteSession: ApiState
}

const initialState: SessionState = {
  session: {},
  getSession: { status: ApiStatus.FULFILLED },
  postSession: { status: ApiStatus.FULFILLED },
  deleteSession: { status: ApiStatus.FULFILLED }
}

export const callGetSession = createAsyncThunk(
  'session/get',
  async () => {
    return await getSession()
  }
)

export const callPostSession = createAsyncThunk(
  'session/post',
  async (jwt?: string) => {
    return await postSession(jwt)
  }
)

export const callDeleteSession = createAsyncThunk(
  'session/delete',
  async () => {
    return await deleteSession()
  }
)

const sessionSlice = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {
  },
  extraReducers: builder => {
    // get session
    builder.addCase(callGetSession.pending, (state) => {
      state.getSession = {
        status: ApiStatus.PENDING,
        error: undefined
      }
    })
    builder.addCase(callGetSession.fulfilled, (state, action) => {
      state.session = action.payload
      state.getSession = {
        status: ApiStatus.FULFILLED,
        error: undefined
      }
    })

    // post session
    builder.addCase(callPostSession.pending, (state) => {
      state.postSession = {
        status: ApiStatus.PENDING,
        error: undefined
      }
    })
    builder.addCase(callPostSession.fulfilled, (state, action) => {
      state.session = action.payload
      state.postSession = {
        status: ApiStatus.FULFILLED,
        error: undefined
      }
    })

    // delete session
    builder.addCase(callDeleteSession.pending, (state) => {
      state.deleteSession = {
        status: ApiStatus.PENDING,
        error: undefined
      }
    })
    builder.addCase(callDeleteSession.fulfilled, (state) => {
      state.session = {}
      state.deleteSession = {
        status: ApiStatus.FULFILLED,
        error: undefined
      }
    })
  }
})

export default sessionSlice.reducer
