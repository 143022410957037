import { Avatar, Button, CircularProgress, IconButton, Tooltip } from '@mui/material'
import { isNil } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'reducers'
import { callDeleteSession, SessionState, User } from 'reducers/session'
import { ScriptStatus } from 'reducers/misc'
import { renderButton } from 'utils/gapi'
import { ApiStatus } from 'reducers/common'

const AnonAvatar = (): React.ReactElement => {
  const gapiStatus = useSelector<AppState, ScriptStatus>(state => state.userState.gapiStatus)
  const themeType = useSelector<AppState, string>(state => state.settingsState.themeType)

  useEffect(() => {
    if (gapiStatus === ScriptStatus.LOADED) {
      renderButton(themeType === 'light')
    }
  }, [gapiStatus, themeType])

  return (
    <Button id='google-sign-in' />
  )
}

const UserAvatar = ({ user }: { user: User }): React.ReactElement => {
  const dispatch = useDispatch()

  return (
    <Tooltip title='Logout'>
      <IconButton onClick={() => dispatch(callDeleteSession())}>
        <Avatar src={user.picture} alt={user.name}>
          {user.name}
        </Avatar>
      </IconButton>
    </Tooltip>
  )
}

const NavAvatar = (): React.ReactElement => {
  const sessionState = useSelector<AppState, SessionState>(state => state.sessionState)

  if (sessionState.getSession.status === ApiStatus.PENDING || sessionState.postSession.status === ApiStatus.PENDING) {
    return <CircularProgress />
  }

  return isNil(sessionState.session?.user)
    ? <AnonAvatar />
    : <UserAvatar {...{ user: sessionState.session.user }} />
}

export default NavAvatar
