import 'utils/AudioContextMonkeyPatch'

import App from 'app'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { isNull } from 'lodash'

const container = document.getElementById('root')
if (!isNull(container)) {
  const root = createRoot(container)
  root.render(<App />)
} else {
  console.error('Cannot find container id=root')
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
